import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Alert } from '@mui/material';
import './newEmployee.css';

const AddEmployeeForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [landId, setLandId] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const token = sessionStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate("/");
      return;
    }
  }, [token, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !name ||
      !email ||
      !role ||
      !landId
    ) {
      setAlertSeverity('error');
      setAlertMessage('Please fill in all the required fields.');
      setAlertOpen(true);
      return;
    }

    try {
      const requestBody = {
        name,
        email,
        role,
        landId,
      };
      console.log("Token:", token);
      const response = await fetch(
        "http://spices.hasthiya.org/employee/createEmployee",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(`Network response was not ok: ${errorMessage}`);
      }

      const responseData = await response.json();
      console.log("Employee added successfully:", responseData);

      setName("");
      setEmail("");
      setRole("");
      setLandId(null);

      setAlertSeverity('success');
      setAlertMessage('Employee added successfully');
      setAlertOpen(true);

    } catch (error) {
      console.error("Error adding employee:", error.message);
      setAlertSeverity('error');
      setAlertMessage(`Error adding employee: ${error.message}`);
      setAlertOpen(true);
    }
  };

  return (

        <div className="new">
          <div className="newContainer">
            <div className="top">
              
                <h1>Add New Employee Details</h1>
              
              
              <Link to="/allEmployee" className="link_back" style={{marginLeft:'800px'}} >
                  Back
              </Link>
            </div>
            <div className="bottom">
              <div className="right">
                <form onSubmit={handleSubmit}>
                  <div className="formInput">
                    <label>Full Name<span className="required">*</span></label>
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Enter full name"
                    />
                  </div>

                  <div className="formInput">
                    <label>Email<span className="required">*</span></label>
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter email"
                    />
                  </div>

                  <div className="formInput">
                    <label>Role<span className="required">*</span></label>
                    <input
                      type="text"
                      value={role}
                      onChange={(e) => setRole(e.target.value)}
                      placeholder="Enter role"
                    />
                  </div>

                  <div className="formInput">
                    <label>Land ID</label>
                    <input
                      type="number"
                      value={landId}
                      onChange={(e) => setLandId(e.target.value ? Number(e.target.value) : null)}
                      placeholder="Enter land ID"
                    />
                  </div>
                  <button className="submit_button" type="submit">Add Employee</button>
                  {alertOpen && (
                    <Alert severity={alertSeverity} onClose={() => setAlertOpen(false)}>
                      {alertMessage}
                    </Alert>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
  );
};

export default AddEmployeeForm;
