import React, { useState, useEffect } from 'react';
import './landDetailsForm.scss';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { HiCalendarDays } from "react-icons/hi2";
import { FaPeopleCarryBox } from "react-icons/fa6";
import { GiWeightScale } from "react-icons/gi";
import { LuBadgeHelp } from "react-icons/lu";
import { FaCartShopping } from "react-icons/fa6";
import { FaRegPaperPlane } from "react-icons/fa6";

const LandDetailsForm = () => {
  const [formData, setFormData] = useState({
    landId:'',
    date: '',
    days: '',
    people: '',
    kilo: '',
    loosingAmount: '',
    perKiloFromShop: '',
  });

  const navigate = useNavigate();

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    if (!token) {
      navigate('/');
    }
  }, [navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = sessionStorage.getItem('token');

    if (!formData.date || !formData.days || !formData.people || !formData.kilo || !formData.loosingAmount || !formData.perKiloFromShop) {
      toast.error('Please fill in all fields');
      return;
    }

    const requestData = {
      landId: formData.landId,
      date: new Date(formData.date).toISOString(),
      days: formData.days,
      people: formData.people,
      kilo: formData.kilo,
      loosingAmount: formData.loosingAmount,
      perKiloFromShop: formData.perKiloFromShop,
    };

    try {
      const response = await fetch('http://spices.hasthiya.org/data/createData', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(requestData)
      });

      if (!response.ok) {
        throw new Error('Failed to submit data');
      }

      toast.success('Form submitted successfully!');
      setFormData({
        landId:'',
        date: '',
        days: '',
        people: '',
        kilo: '',
        loosingAmount: '',
        perKiloFromShop: '',
      });

      setTimeout(() => {
        navigate('/allproductdata');
      }, 3000);

    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error('Failed to submit form');
    }
  };

  return (
    <div className="landDetailsForm">
      <h2>Add Land Details</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="landId">
            <HiCalendarDays className="icon" /> Land Id
          </label>
          <TextField
            type="number"
            id="landId"
            name="landId"
            value={formData.landId}
            onChange={handleChange}
            variant="outlined"
            fullWidth
          />
        </div>
        <div className="form-group">
          <label htmlFor="date">
            <HiCalendarDays className="icon" /> Date
          </label>
          <TextField
            type="date"
            id="date"
            name="date"
            value={formData.date}
            onChange={handleChange}
            variant="outlined"
            fullWidth
          />
        </div>
        <div className="form-group">
          <label htmlFor="days">
            <HiCalendarDays className="icon" /> No of Work Days
          </label>
          <TextField
            type="number"
            id="days"
            name="days"
            value={formData.days}
            onChange={handleChange}
            variant="outlined"
            fullWidth
          />
        </div>
        <div className="form-group">
          <label htmlFor="people">
            <FaPeopleCarryBox className="icon" /> No of People
          </label>
          <TextField
            type="number"
            id="people"
            name="people"
            value={formData.people}
            onChange={handleChange}
            variant="outlined"
            fullWidth
          />
        </div>
        <div className="form-group">
          <label htmlFor="kilo">
            <GiWeightScale className="icon" /> Kilo
          </label>
          <TextField
            type="number"
            id="kilo"
            name="kilo"
            value={formData.kilo}
            onChange={handleChange}
            variant="outlined"
            fullWidth
          />
        </div>
        <div className="form-group">
          <label htmlFor="loosingAmount">
            <LuBadgeHelp className="icon" /> Loosing Amount
          </label>
          <TextField
            type="number"
            id="loosingAmount"
            name="loosingAmount"
            value={formData.loosingAmount}
            onChange={handleChange}
            variant="outlined"
            fullWidth
          />
        </div>
        <div className="form-group">
          <label htmlFor="perKiloFromShop">
            <FaCartShopping className="icon" />
            Amount Per Kilo
          </label>
          <TextField
            type="number"
            id="perKiloFromShop"
            name="perKiloFromShop"
            value={formData.perKiloFromShop}
            onChange={handleChange}
            variant="outlined"
            fullWidth
          />
        </div>
        <Button
          type="submit"
          variant="contained"
          sx={{
            backgroundColor: "hsl(94, 59%, 35%)",
            "&:hover": { backgroundColor: "hsl(94, 59%, 45%)" },
          }}
          startIcon={<FaRegPaperPlane className="icon" />}
        >
          Submit
        </Button>
      </form>
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </div>
  );
};

export default LandDetailsForm;
