import React, { useState, useEffect } from 'react';
import './AllProductData.scss';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify'; // Import toast components
import 'react-toastify/dist/ReactToastify.css'; // Import toast styles
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button'; // Import Button from Material-UI
import { CiEdit } from "react-icons/ci";
import { RiDeleteBin3Line } from "react-icons/ri";

const AllProductData = () => {
  const [landDetails, setLandDetails] = useState([]);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    if (!token) {
      navigate('/');
      return;
    }

    const fetchLandDetails = async () => {
      try {
        const response = await fetch('http://spices.hasthiya.org/data/getAll', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch land details');
        }

        const responseData = await response.json();

        // Check if the response contains the expected nested structure
        if (responseData.status && responseData.data && Array.isArray(responseData.data.data)) {
          setLandDetails(responseData.data.data);
        } else {
          throw new Error('Unexpected response format');
        }
      } catch (error) {
        console.error('Error fetching land details:', error);
        setError('Failed to fetch land details');
      }
    };

    fetchLandDetails();
  }, [navigate]);

  const handleEdit = (detailsId) => {
    navigate(`/edit-product/${detailsId}`);
  };

  const handleDelete = async (detailsId) => {
    const token = sessionStorage.getItem('token');

    // Show confirmation toast
    toast.dark(
      ({ closeToast }) => (
        <div>
          <p>Are you sure you want to delete this product data?</p>
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              deleteItemConfirmed(detailsId);
              closeToast();
            }}
            style={{ backgroundColor: 'red', color: 'white', marginRight: 10 }}
          >
            Yes
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={closeToast}
            style={{ backgroundColor: 'green', color: 'white' }}
          >
            No
          </Button>
        </div>
      ),
      { autoClose: false }
    );

    // Function to handle item deletion when confirmed
    const deleteItemConfirmed = async (detailsId) => {
      try {
        const response = await fetch(`http://spices.hasthiya.org/data/delete/${detailsId}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to delete land details');
        }

        // Remove the deleted item from state
        setLandDetails(landDetails.filter(detail => detail.detailsId !== detailsId));

        // Show success toast
        toast.success('Successfully deleted the product details!', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (error) {
        console.error('Error deleting land details:', error);
        setError('Failed to delete land details');

        // Show error toast
        toast.error('Failed to delete the land details. Please try again later.', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    };
  };

  return (
    <div className="allProductData">
      <h2>All Product Data</h2>
      {error && <Alert severity="error">{error}</Alert>}
      <ToastContainer /> {/* ToastContainer for displaying toast notifications */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Details ID</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>No Of Days</TableCell>
              <TableCell>No Of People</TableCell>
              <TableCell>Total Kilos Get</TableCell>
              <TableCell>Loosing Kilos</TableCell>
              <TableCell>Remain Kilo</TableCell>
              <TableCell>Average Kilo per Day</TableCell>
              <TableCell>Amount Per Kilo</TableCell>
              <TableCell>Wasted value</TableCell>
              <TableCell>Total value Received</TableCell>
              <TableCell>Estimate value</TableCell>
              <TableCell>Land ID</TableCell>
              <TableCell>Actions</TableCell> {/* Added Actions column */}
            </TableRow>
          </TableHead>
          <TableBody>
            {landDetails.map((detail) => (
              <TableRow key={detail.detailsId}>
                <TableCell>{detail.detailsId}</TableCell>
                <TableCell>{new Date(detail.date).toLocaleDateString()}</TableCell>
                <TableCell>{detail.days}</TableCell>
                <TableCell>{detail.people}</TableCell>
                <TableCell>{detail.kilo}</TableCell>
                <TableCell>{detail.loosingAmount}</TableCell>
                <TableCell>{detail.fullKilo}</TableCell>
                <TableCell>{detail.avgKiloPerDay.toFixed(2)}</TableCell>
                <TableCell>{detail.perKiloFromShop}</TableCell>
                <TableCell>{detail.totalWastedKilo}</TableCell>
                <TableCell>
                  <span style={{ color: 'red' }}>{detail.totalKiloSetFromShop}</span>
                </TableCell>
                <TableCell>{detail.amountForTotalKilo}</TableCell>
                <TableCell>{detail.landId}</TableCell>
                <TableCell>
                  <CiEdit className="icon-button" onClick={() => handleEdit(detail.detailsId)} />
                  <RiDeleteBin3Line className="icon-button" onClick={() => handleDelete(detail.detailsId)} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default AllProductData;
