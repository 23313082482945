import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./sidebar.css";
import "./sidebar.scss";

import logo from "../../Assets/logo.jpg";
import { IoMdSpeedometer } from "react-icons/io";
import { MdDeliveryDining } from "react-icons/md";
import { BsTrophy } from "react-icons/bs";
import { MdOutlineExplore } from "react-icons/md";
import { AiOutlinePieChart } from "react-icons/ai";
import { BiTrendingUp } from "react-icons/bi";
import { MdOutlinePermContactCalendar } from "react-icons/md";
import { BsCreditCard } from "react-icons/bs";
import { BsQuestionCircle } from "react-icons/bs";

const Sidebar = () => {
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <div className="sideBar grid">
      <div className="logoDiv flex">
        <img src={logo} alt="Logo" />
        <h2>Spices Land</h2>
      </div>
      <div className="menuDiv">
        <h3 className="divTitle">Quick Menu</h3>
        <ul className="menuLists grid">
          <li className="listItem">
            <a href="/dashboard" className="menuLink flex">
              <IoMdSpeedometer className="icon" />
              <span className="smallText">Dashboard</span>
            </a>
          </li>
          <li className="listItem">
            <a href="/allEmployee" className="menuLink flex">
              <IoMdSpeedometer className="icon" />
              <span className="smallText">Employee</span>
            </a>
          </li>
          <li className="listItem">
            <Link
              to="/allland"
              className="menuLink flex"
              onClick={toggleDropdown}
            >
              <MdDeliveryDining className="icon" />
              <span className="smallText">Spices Land</span>
            </Link>
            {showDropdown && (
              <ul className="dropdownMenu">
              <li className="dropdownItem">
                <Link to="/state/11" className="menuLink">
                  Land 1
                </Link>
              </li>
              <li className="dropdownItem">
                <Link to="/state/2" className="menuLink">
                  Land 2
                </Link>
              </li>
              <li className="dropdownItem">
                <Link to="/state/3" className="menuLink">
                  Land 3
                </Link>
              </li>
            </ul>
            )}
          </li>
          <li className="listItem">
            <a href="/allproductdata" className="menuLink flex">
              <BsTrophy className="icon" />
              <span className="smallText">Product</span>
            </a>
          </li>
          <li className="listItem">
            <a href="#" className="menuLink flex">
              <MdOutlineExplore className="icon" />
              <span className="smallText">Explore</span>
            </a>
          </li>
        </ul>
      </div>

      <div className="settingsDiv">
        <h3 className="divTitle">Settings</h3>
        <ul className="menuLists grid">
          <li className="listItem">
            <a href="#" className="menuLink flex">
              <AiOutlinePieChart className="icon" />
              <span className="smallText">Charts</span>
            </a>
          </li>
          <li className="listItem">
            <a href="#" className="menuLink flex">
              <BiTrendingUp className="icon" />
              <span className="smallText">Trends</span>
            </a>
          </li>
          <li className="listItem">
            <a href="#" className="menuLink flex">
              <MdOutlinePermContactCalendar className="icon" />
              <span className="smallText">Contact</span>
            </a>
          </li>
          <li className="listItem">
            <a href="#" className="menuLink flex">
              <BsCreditCard className="icon" />
              <span className="smallText">Billing</span>
            </a>
          </li>
        </ul>
      </div>

      <div className="sideBarCard">
        <BsQuestionCircle className="icon" />
        <div className="cardContent">
          <div className="circle1"></div>
          <div className="circle2"></div>
          <h3>Help Center</h3>
          <p>Do you have Any Problem ? Please Contact Us</p>
          <button className="btn">Go to Help Center</button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
