import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import "./singleEmployee.css";


const EditEmployeePage = () => {
  const { emp_id } = useParams();
  const navigate = useNavigate();
  const token = sessionStorage.getItem("token");
  const [employee, setEmployee] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    role: "",
    landId: "",
  });
  
  const [alert, setAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });

  useEffect(() => {
    if (!token) {
      navigate("/");
      return;
    }
  }, [token, navigate]);

  useEffect(() => {
    const fetchEmployee = async () => {
      try {
        const response = await fetch(
          `http://spices.hasthiya.org/employee/get/${emp_id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          const errorText = await response.text();
          console.error("Failed to fetch employee details:", errorText);
          setAlert({
            open: true,
            severity: "error",
            message: `Failed to fetch employee details: ${errorText}`,
          });
          return;
        }

        const data = await response.json();
        if (!data.data) {
          setAlert({
            open: true,
            severity: "error",
            message: "Employee not found",
          });
          return;
        }

        setEmployee(data.data);
        console.log(data.data);
        setFormData({
          name: data.data.name,
          email: data.data.email,
          role: data.data.role,
          landId: data.data.landId,
        });
      } catch (error) {
        console.error("Error fetching employee details:", error);
        setAlert({
          open: true,
          severity: "error",
          message: "Error fetching employee details",
        });
      }
    };

    fetchEmployee();
  }, [emp_id, token]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `http://spices.hasthiya.org/employee/update/${emp_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(formData),
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Failed to update employee:", errorText);
        setAlert({
          open: true,
          severity: "error",
          message: `Failed to update employee: ${errorText}`,
        });
        return;
      }

      setAlert({
        open: true,
        severity: "success",
        message: "Employee updated successfully",
      });
      setTimeout(() => {
        navigate("/allEmployee", { state: { reload: true } });
      }, 2000);
    } catch (error) {
      console.error("Error updating employee:", error);
      setAlert({
        open: true,
        severity: "error",
        message: "Error updating employee",
      });
    }
  };

  return (
    
        <div className="edit-employee-page__content">
          <h1 className="title">Employee Details</h1>

          {employee ? (
            <form onSubmit={handleSubmit}>
              <div className="text_inputs">
                <TextField
                  className="textfield"
                  label="Full Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  margin="normal"
                />
                <TextField
                  label="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="textfield"
                  margin="normal"
                />
                <TextField
                  label="Role"
                  name="role"
                  value={formData.role}
                  onChange={handleChange}
                  className="textfield"
                  margin="normal"
                />
                <TextField
                  label="Land ID"
                  name="landId"
                  value={formData.landId}
                  onChange={handleChange}
                  className="textfield"
                  margin="normal"
                />
              </div>
              <div className="bottom_button">
                <button
                  className="button-save"
                  type="submit"
                >
                  Save
                </button>
                <button
                  className="button-save"
                  onClick={() => navigate(-1)}
                >
                  Back
                </button>
              </div>
              
            </form>
          ) : (
            <p>Loading...</p>
          )}
          {alert.open && (
            <Alert
              severity={alert.severity}
              onClose={() => setAlert({ ...alert, open: false })}
            >
              {alert.message}
            </Alert>
          )}
        </div>
    
    
  );
};

export default EditEmployeePage;
