import React from 'react';
import './top.css'; // Ensure this file is imported if it contains other necessary styles
import './top.scss';

import { BiSearch } from "react-icons/bi";
import { MdOutlineMessage } from "react-icons/md";
import { MdOutlineNotificationAdd } from "react-icons/md";
import { BsArrowRightCircleFill } from "react-icons/bs";

import img from '../../../Assets/admin1.jpg';
import img3 from '../../../Assets/img3.png';
import video from '../../../Assets/video2.mp4';

const Top = () => {
    return (
      <div className="topSection">
        <div className="headerSection flex">
          <div className="title">
            <h1>Welcome to Spices World.</h1>
            <p>Hi, Amasha Welcome Back!</p>
          </div>

          <div className="searchBar flex">
            <input type="text" placeholder="Search Dashboard" />
            <BiSearch className="icon" />
          </div>

          <div className="adminDiv flex">
            <MdOutlineMessage className="icon" />
            <MdOutlineNotificationAdd className="icon" />

            <div className="adminImage">
              <img src={img} alt="Admin" />
            </div>
          </div>
        </div>

        <div className="cardSection flex">
          <div className="rightCard flex">
            <h1>Create and sell your products</h1>
            <p>This is the best palce to you can achive your target</p>

            <div className="buttons flex">
              <button className="btn"> Find More</button>
              <button className="btn transparent"> Top Sellers</button>
            </div>

            <div className="videoDiv">
              <video src={video} autoPlay loop muted></video>
            </div>
          </div>

          <div className="leftCard flex">
            <div className="main flex">
              <div className="textDiv">
                <h1>My Stat</h1>

                <div className="flex">
                  <span>
                    Today <br /> <small>4 orders</small>
                  </span>
                  <span>
                    This Month
                    <br /> <small>120 orders</small>
                  </span>
                </div>

                <span className="flex Link">
                  My Orders <BsArrowRightCircleFill className="icon" />
                </span>
              </div>

              <div className="imgDiv">
                <img src={img3} alt="Image Name" />
              </div>

              {/* <div className="sideBarCard">
                <BsQuestionCircle className="icon" />
                <div className="cardContent">
                  <div className="circle1"></div>
                  <div className="circle2"></div>

                  <h3>Help Center</h3>
                  <p>Do you have Any Problem ? Please Contact Us</p>
                  <button className="btn">Go to Help Center</button>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
}

export default Top;