

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './allLand.scss';

const LandDetails = () => {
  const [landData, setLandData] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const token = sessionStorage.getItem("token"); //session part

  useEffect(() => {///////////////////////////
    if (!token) {
      navigate("/");
      return;
    }
  });

  useEffect(() => {
    const fetchLandData = async () => {
      try {
        const response = await fetch('http://spices.hasthiya.org/lands/getAll', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        if (data?.data?.data && Array.isArray(data.data.data)) {
          setLandData(data.data.data);
        } else {
          throw new Error('Data format error: Expected an array');
        }
      } catch (error) {
        setError(error.message);
        console.error('Error fetching land data:', error);
      }
    };

    fetchLandData();
  }, []);

  const showToast = (id) => {
    toast(
      ({ closeToast }) => (
        <div>
          <p>Are you sure you want to delete this land?</p>
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              handleDelete(id);
              closeToast();
            }}
            style={{ backgroundColor: 'red', color: 'white', marginRight: 10 }}
          >
            Yes
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={closeToast}
            style={{ backgroundColor: 'green', color: 'white' }}
          >
            No
          </Button>
        </div>
      ),
      { autoClose: false }
    );
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`http://spices.hasthiya.org/lands/delete/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      setLandData((prevLandData) => prevLandData.filter((land) => land.LandId !== id));
      toast.success('Successfully deleted!');
    } catch (error) {
      setError(error.message);
      toast.error('Error deleting land data');
      console.error('Error deleting land data:', error);
    }
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  const columns = [
    { field: 'LandId', headerName: 'ID', width: 90 },
    { field: 'land_name', headerName: 'Land Name', width: 150 },
    { field: 'land_size', headerName: 'Land Size', width: 150 },
    { field: 'supervisors', headerName: 'Supervisor Name', width: 150 },
    { field: 's_phone', headerName: 'Contact', width: 130 },
    { field: 'Spices', headerName: 'Spices', width: 130 },
    {
      field: 'action',
      headerName: 'Action',
      width: 250,
      renderCell: (params) => (
        <div>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ backgroundColor: 'hsl(94, 59%, 35%', color: 'white', marginRight: 10 }}
            onClick={() => navigate(`/land/${params.id}`)}
          >
            View & update
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => showToast(params.id)}
            style={{ backgroundColor: 'rgba(195, 65, 65, 0.592', color: 'white', marginRight: 10 }}
          >
            Delete
          </Button>
        </div>
      )
    }
  ];

  return (
    <div className="landDetailsContainer">
      <ToastContainer />
      <h2 className="pageTitle">Land Management</h2>
      <div style={{ height: 800, width: '95%' }}>
        <DataGrid
          rows={landData}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10, 20, 50]}
          getRowId={(row) => row.LandId}
        />
      </div>
      <Button
        variant="contained"
        color="primary"
        style={{ marginTop: 16 }}
        onClick={() => navigate('/addland')}
      >
        Add New Land
      </Button>
    </div>
  );
};

export default LandDetails;
