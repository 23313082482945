

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaRegPaperPlane } from "react-icons/fa6";
import './addland.scss';



const LandDetailsForm = () => {
  const [formData, setFormData] = useState({
    land_name: '',
    land_size: '',
    landAddress: '',
    Spices: '',
    supervisors: '',
    s_phone: '',
    email: '',
    address: ''
  });

  const token = sessionStorage.getItem("token"); //session part

  useEffect(() => {///////////////////////////
    if (!token) {
      navigate("/");
      return;
    }
  });

  const [validationErrors, setValidationErrors] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    setValidationErrors({
      ...validationErrors,
      [name]: ''
    });
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.land_name) errors.land_name = 'This field is required';
    if (!formData.land_size) errors.land_size = 'This field is required';
    if (!formData.landAddress) errors.landAddress = 'This field is required';
    if (!formData.Spices) errors.Spices = 'This field is required';
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      toast.error('Please fill in all required fields');
      return;
    }
    try {
      const response = await fetch('http://spices.hasthiya.org/lands/createLand', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(formData)
      });
      if (!response.ok) {
        throw new Error('Failed to add land details');
      }
      toast.success('Land details added successfully!');
      setTimeout(() => {
        navigate('/allland');
      }, 2000);
      setFormData({
        land_name: '',
        land_size: '',
        landAddress: '',
        Spices: '',
        supervisors: '',
        s_phone: '',
        email: '',
        address: ''
      });
    } catch (error) {
      console.error('Error adding land details:', error);
      toast.error('Failed to add land details');
    }
  };

  return (
    <div className="landDetailsForm">
      <h2>Add New Land Details</h2>
      <form onSubmit={handleSubmit}>
        <div className='form-left'>
          <div className="form-group">
          <label htmlFor="land_name">Land Name*</label>
          <input
            type="text"
            id="land_name"
            name="land_name"
            placeholder="Enter land name"
            value={formData.land_name}
            onChange={handleChange}
          />
          {validationErrors.land_name && <span className="error">{validationErrors.land_name}</span>}
        </div>
        <div className="form-group">
          <label htmlFor="land_size">Land Size*</label>
          <input
            type="text"
            id="land_size"
            name="land_size"
            placeholder="Enter land size"
            value={formData.land_size}
            onChange={handleChange}
          />
          {validationErrors.land_size && <span className="error">{validationErrors.land_size}</span>}
        </div>
        <div className="form-group">
          <label htmlFor="landAddress">Land Address*</label>
          <input
            type="text"
            id="landAddress"
            name="landAddress"
            placeholder="Enter address"
            value={formData.landAddress}
            onChange={handleChange}
          />
          {validationErrors.landAddress && <span className="error">{validationErrors.landAddress}</span>}
        </div>
        <div className="form-group">
          <label htmlFor="Spices">Spices*</label>
          <input
            type="text"
            id="Spices"
            name="Spices"
            placeholder="Enter Type of spices in your land"
            value={formData.Spices}
            onChange={handleChange}
          />
          {validationErrors.Spices && <span className="error">{validationErrors.Spices}</span>}
        </div>
        </div>
        <div className='form-right'>
          <div className="form-group">
          <label htmlFor="supervisors">Supervisor Name</label>
          <input
            type="text"
            id="supervisors"
            name="supervisors"
            placeholder="Enter supervisor name"
            value={formData.supervisors}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="s_phone">Contact No</label>
          <input
            type="text"
            id="s_phone"
            name="s_phone"
            placeholder="Enter contact number"
            value={formData.s_phone}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Enter email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="address">Address</label>
          <input
            type="text"
            id="address"
            name="address"
            placeholder="Enter address"
            value={formData.address}
            onChange={handleChange}
          />
        </div>
        </div>
        
        <button type="submit" className="submitButton">
          <FaRegPaperPlane className="icon" /> Submit
        </button>
      </form>
      <ToastContainer />
    </div>
  );
};

export default LandDetailsForm;
