import React from 'react';
import './activity.scss'


import user1 from '../../../Assets/user1.jpg';
import user2 from '../../../Assets/user2.jpg';
import user3 from '../../../Assets/user3.jpg';

import { BsArrowRightCircleFill } from "react-icons/bs";

const Activity = () => {
    return (
        <div className='activitySection'>
            <div className='heading flex'>
                <h1>Recent Activity</h1>
                <button className='btn flex'>
                           See All <BsArrowRightCircleFill className="icon"/>
                    </button>
            </div>
           
           <div className='secContainer grid'>
            <div className='singleCustomer flex'>
            <img src={user1} alt="user image" />
            <div className='customerDetails'>
                <span className='name'> Chamadi</span>
                <small>put a new order</small>
            </div>
            <div className='duration'>
                4 min ago
            </div>
            </div>
            <div className='singleCustomer flex'>
            <img src={user2} alt="user image" />
            <div className='customerDetails'>
                <span className='name'> Inoka</span>
                <small>Updated sales details</small>
            </div>
            <div className='duration'>
                15 min ago
            </div>
            </div>
            <div className='singleCustomer flex'>
            <img src={user3} alt="user image" />
            <div className='customerDetails'>
                <span className='name'> Hiruni</span>
                <small>Updated product details</small>
            </div>
            <div className='duration'>
                25 min ago
            </div>
            </div>
            
           </div>

        </div>
    );
}

export default Activity;
