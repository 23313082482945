import React, { useState } from 'react';
import './addemployee.scss';

import { FaUserTie } from "react-icons/fa";
import { MdOutlineWorkOutline } from "react-icons/md";
import { MdEmail } from "react-icons/md";
import { FaRegPaperPlane } from "react-icons/fa6";

const AddEmployeeForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    role: '',
    email: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log(formData);
  };

  return (
    <div className="addEmployeeForm">
      <h2>Add Employee</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name"><FaUserTie className='icon' /> Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="role"><MdOutlineWorkOutline className='icon'/> Role</label>
          <input
            type="text"
            id="role"
            name="role"
            value={formData.role}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="email"><MdEmail className='icon' /> Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <button type="submit" className="btn"><FaRegPaperPlane className='icon' /> Submit</button>
      </form>
    </div>
  );
};

export default AddEmployeeForm;
