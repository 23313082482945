import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./app.css";
import Sidebar from "./Components/SideBar Section/Sidebar";
import Body from "./Components/Body Section/Body";
import State from "./pages/state/State";
import LandDetailsForm from "./pages/LandDetailsForm/LandDeatilsForm";
import AddEmployee from "./pages/employeeadd/AddEmployee";
import Addland from "./pages/Addland/Addland";
import AllLand from "./pages/AllLand/AllLand";
import Login from "./pages/login/Login";
import EmployeeDataTable from "./pages/Employee/EmployeeDataTable";
import SingleEmployee from "./pages/Employee/SingleEmployee";
import NewEmployee from "./pages/Employee/NewEmployee";
import LandDetailPage from "./pages/Editland/LandDetailsEdit";
import AllProductData from "./pages/Allproductdata/AllProductData"
import EditProductData from "./pages/EditProductData/EditProductData";


const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/*"
          element={
            <div className="container">
              <Sidebar />
              <Routes>
                <Route path="/dashboard" element={<Body />} />
                <Route path="/state/:id" element={<State />} />
                <Route path="/landDetailsForm" element={<LandDetailsForm />} />
                <Route path="/addEmployee" element={<AddEmployee />} />
                <Route path="/addland" element={<Addland />} />
                <Route path="/allland" element={<AllLand />} />
                <Route path="/allEmployee">
                  <Route index element={<EmployeeDataTable />} />
                  <Route path=":emp_id" element={<SingleEmployee />} />
                  <Route
                    path="newEmployee"
                    element={<NewEmployee />}
                  />
                </Route>
                <Route path="/land/:id" element={<LandDetailPage />} />
                <Route path="/allproductdata" element={<AllProductData />} />
                <Route path="/edit-product/:id" element={<EditProductData />} />

              </Routes>
            </div>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
