import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import './EditProductData.scss';

const EditProductData = () => {
  const { id } = useParams();
  const [productData, setProductData] = useState(null);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    if (!token) {
      navigate('/');
      return;
    }

    const fetchProductData = async () => {
      try {
        const response = await fetch(`http://spices.hasthiya.org/data/get/${id}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch product data');
        }

        const responseData = await response.json();
        // Extract only the required fields
        const { date, days, people, kilo, loosingAmount, perKiloFromShop } = responseData.data;
        setProductData({ date, days, people, kilo, loosingAmount, perKiloFromShop });
      } catch (error) {
        console.error('Error fetching product data:', error);
        setError('Failed to fetch product data');
      }
    };

    fetchProductData();
  }, [id, navigate]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setProductData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const token = sessionStorage.getItem('token');

    try {
      const response = await fetch(`http://spices.hasthiya.org/data/update/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(productData),
      });

      if (!response.ok) {
        throw new Error('Failed to update product data');
      }

      // Show success toast
      toast.success('Successfully updated the product details!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClose: () => navigate('/allproductdata') // Navigate after toast is closed
      });
    } catch (error) {
      console.error('Error updating product data:', error);
      setError('Failed to update product data');

      // Show error toast
      toast.error('Failed to update the product data. Please try again later.', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  if (!productData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="editProductData">
      <h2>Edit Product Data</h2>
      {error && <div className="error">{error}</div>}
      <ToastContainer />
      <form onSubmit={handleSubmit}>
        <TextField
          label="Date"
          name="date"
          type="date"
          value={productData.date.split('T')[0]}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          label="No Of Days"
          name="days"
          value={productData.days}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="No Of People"
          name="people"
          value={productData.people}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Total Kilos Get"
          name="kilo"
          value={productData.kilo}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Loosing Kilos"
          name="loosingAmount"
          value={productData.loosingAmount}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Amount Per Kilo"
          name="perKiloFromShop"
          value={productData.perKiloFromShop}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
        />
        <Button type="submit" variant="contained" color="primary">
          Update Product Data
        </Button>
      </form>
    </div>
  );
};

export default EditProductData;
