import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IoArrowBackCircle } from 'react-icons/io5';
import './landDetailPage.scss';

const LandDetailPage = () => {
  const { id } = useParams();
  const [landDetail, setLandDetail] = useState({
    LandId: '',
    land_name: '',
    land_size: '',
    landAddress: '',
    supervisors: '',
    s_phone: '',
    Spices: ''
  });
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const token = sessionStorage.getItem("token"); //session part

  useEffect(() => {///////////////////////////
    if (!token) {
      navigate("/");
      return;
    }
  });

  useEffect(() => {
    const fetchLandDetail = async () => {
      try {
        const response = await fetch(`http://spices.hasthiya.org/lands/get/${id}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setLandDetail(data.data);
      } catch (error) {
        setError(error.message);
        console.error('Error fetching land detail:', error);
      }
    };

    fetchLandDetail();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLandDetail((prevDetail) => ({
      ...prevDetail,
      [name]: value
    }));
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`http://spices.hasthiya.org/lands/update/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(landDetail)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      toast.success('Land details updated successfully!');
      setTimeout(() => navigate('/allland'), 2000);
    } catch (error) {
      setError(error.message);
      toast.error('Failed to update land details.');
      console.error('Error updating land detail:', error);
    }
  };

  const handleBackToList = () => {
    navigate('/allland');
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!landDetail) {
    return <div>Loading...</div>;
  }

  return (
    <div className="landDetailPage">
      <ToastContainer />
      <div className="backIcon" onClick={handleBackToList}>
        <IoArrowBackCircle size={40} />
      </div>
      <h2 className="pageTitle">Edit Land Detail</h2>
      <form className="detailContainer" onSubmit={handleUpdate}>
        <div className="form-group">
          <label>ID:</label>
          <input type="text" name="LandId" value={landDetail.LandId} readOnly />
        </div>
        <div className="form-group">
          <label>Land Name:</label>
          <input type="text" name="land_name" value={landDetail.land_name} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Land Size:</label>
          <input type="text" name="land_size" value={landDetail.land_size} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Land Address:</label>
          <input type="text" name="landAddress" value={landDetail.landAddress} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Supervisor Name:</label>
          <input type="text" name="supervisors" value={landDetail.supervisors} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Contact:</label>
          <input type="text" name="s_phone" value={landDetail.s_phone} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Spices:</label>
          <input type="text" name="Spices" value={landDetail.Spices} onChange={handleChange} />
        </div>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          style={{ backgroundColor: ' hsl(94, 59%, 35%', color: 'white' }}
        >
          Update Land Details
        </Button>
      </form>
    </div>
  );
};

export default LandDetailPage;
