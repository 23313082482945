import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams, useNavigate } from "react-router-dom";
import { PDFDownloadLink, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import "./state.scss";
import { FaArrowRight } from 'react-icons/fa';

const styles = StyleSheet.create({
  page: {
    padding: 20,
    backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  text: {
    marginBottom: 10,
    fontSize: 12,
  },
  header: {
    marginBottom: 10,
    fontSize: 14,
    fontWeight: 'bold',
  },
  subheader: {
    marginBottom: 10,
    fontSize: 13,
    fontWeight: 'bold',
  }
});

const StatePage = () => {
  const { id } = useParams(); // Assuming you're using react-router to get the id from the URL
  const navigate = useNavigate();
  const [employees, setEmployees] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const landId = 1;
  const [landDetails, setLandDetails] = useState(null);
  const [productDetails, setProductDetails] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const token = sessionStorage.getItem("token");

  useEffect(() => {
    if (!token) {
      navigate("/");
      return;
    }

    const fetchData = async () => {
      try {
        const response = await fetch(
          "http://spices.hasthiya.org/employee/getAll",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch employee data");
        }

        const responseData = await response.json();
        if (responseData.status) {
          setEmployees(responseData.data.data);
        } else {
          throw new Error(responseData.message);
        }
      } catch (error) {
        console.error("Error fetching employee data:", error.message);
      }
    };

    fetchData();

    const fetchLandDetails = async () => {
      try {
        const response = await axios.get(`http://spices.hasthiya.org/lands/get/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        const data = response.data.data;
        setLandDetails({
          landName: data.land_name,
          landSize: data.land_size,
          address: data.landAddress,
          spices: data.Spices.split(","),
          supervisor: data.supervisors,
          email: data.email,
          images: [
            "https://www.homelands.lk/news-events/content/images/size/w2000/2023/05/06.png",
            "https://images.pexels.com/photos/440731/pexels-photo-440731.jpeg?auto=compress&cs=tinysrgb&w=600",
            "https://images.pexels.com/photos/5529950/pexels-photo-5529950.jpeg?auto=compress&cs=tinysrgb&w=600",
            "https://images.pexels.com/photos/4946873/pexels-photo-4946873.jpeg?auto=compress&cs=tinysrgb&w=600",
            "https://images.pexels.com/photos/5696768/pexels-photo-5696768.jpeg?auto=compress&cs=tinysrgb&w=600"
          ]
        });
      } catch (error) {
        console.error("Error fetching land details:", error);
      }
    };

    const fetchProductDetails = async () => {
      try {
        const response = await axios.get(`http://spices.hasthiya.org/data/LandRelatedDetails/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
    
        const formattedData = response.data.data.map(row => ({
          ...row,
          date: new Date(row.date).toISOString().split('T')[0] // Convert ISO date to YYYY-MM-DD format
        }));
    
        setProductDetails(formattedData);
      } catch (error) {
        console.error("Error fetching product details:", error);
      }
    };
    
    fetchLandDetails();
    fetchProductDetails();
  }, [id, navigate, token]);

  useEffect(() => {
    const filtered = employees.filter(employee => employee.landID === landId);
    setFilteredEmployees(filtered);
  }, [employees, landId]);

  const handleImageSwap = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % (landDetails?.images.length || 1));
  };

  const renderDetailsTablePDF = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.header}>Land Details</Text>
          <Text style={styles.text}>Land Name: {landDetails?.landName}</Text>
          <Text style={styles.text}>Location: {landDetails?.location}</Text>
          <Text style={styles.text}>Address: {landDetails?.address}</Text>
          <Text style={styles.text}>Supervisor: {landDetails?.supervisor}</Text>
          <Text style={styles.text}>Land Size: {landDetails?.landSize}</Text>
          <Text style={styles.text}>Spices: {landDetails?.spices.join(", ")}</Text>
          
          <Text style={styles.header}>Details of the Production Process</Text>
          {productDetails.map(row => (
            <View key={row.detailsId} style={styles.section}>
              <Text style={styles.subheader}>Date: {new Date(row.date).toLocaleDateString()}</Text>
              <Text style={styles.text}>No of Work Days: {row.days}</Text>
              <Text style={styles.text}>No of People: {row.people}</Text>
              <Text style={styles.text}>Total Kilos: {row.kilo} Kg</Text>
              <Text style={styles.text}>Loosing Kilos: {row.loosingAmount} Kg</Text>
              <Text style={styles.text}>Final Count of Full Kilos: {row.fullKilo} Kg</Text>
              <Text style={styles.text}>Average Kilos per Day: {row.avgKiloPerDay} Kg</Text>
              <Text style={styles.text}>The Shop Provided Amount per Kilos : {row.perKiloFromShop} Kg</Text>
              <Text style={styles.text}>Total Wasted value: {row.totalWastedKilo} Kg</Text>
              <Text style={styles.text}>Total value we Received: {row.totalKiloSetFromShop} Kg</Text>
              <Text style={styles.text}>Estimated value: {row.amountForTotalKilo} Kg</Text>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );

  if (!landDetails) {
    return <div>Loading...</div>;
  }

  return (
    <div className="statePage">
      <div className="landDetails">
        <div className="imageContainer">
          <img src={landDetails.images[currentImageIndex]} alt="Land" />
          <button className="swapButton" onClick={handleImageSwap}>
            <FaArrowRight />
          </button>
        </div>
        <div className="details">
          <h2>Land Details</h2>
          <p><strong>Land Name:</strong> {landDetails.landName}</p>
          <p><strong>Land Size:</strong> {landDetails.landSize}</p>
          <p><strong>Address:</strong> {landDetails.address}</p>
          <p><strong>Spices:</strong> {landDetails.spices.join(", ")}</p>
          <p><strong>Supervisor:</strong> {landDetails.supervisor}</p>
          <p><strong>Supervisor's Email:</strong> {landDetails.email}</p>
        </div>
      </div>

      <div className="employeeDetails">
        <h3>Employee Details</h3>
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Role</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            {filteredEmployees.map(row => (
              <tr key={row.id}>
                <td>{row.id}</td>
                <td>{row.name}</td>
                <td>{row.role}</td>
                <td>{row.email}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <br />
      <br />
      <div className="detailsTable">
        <h3>Details Table</h3>
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>No of Work Days</th>
              <th>No of People</th>
              <th>Total Kilos get (Kg)</th>
              <th>Loosing Kilos (Kg)</th>
              <th>Remain Kilos (Kg)</th>
              <th>Average Kilo per Day (Kg)</th>
              <th>Amount for kilo (Kg)</th>
              <th>Total Wasted value (Kg)</th>
              <th>Total value Received (Kg)</th>
              <th>Estimated value from product</th>
              <th>Created At</th>
              <th>Updated At</th>
            </tr>
          </thead>
          <tbody>
            {productDetails.map(row => (
              <tr key={row.detailsId}>
                <td>{new Date(row.date).toLocaleDateString()}</td>
                <td>{row.days}</td>
                <td>{row.people}</td>
                <td>{row.kilo}</td>
                <td>{row.loosingAmount}</td>
                <td>{row.fullKilo}</td>
                <td>{row.avgKiloPerDay}</td>
                <td>{row.perKiloFromShop}</td>
                <td>{row.totalWastedKilo}</td>
                <td>{row.totalKiloSetFromShop}</td>
                <td>{row.amountForTotalKilo}</td>
                <td>{new Date(row.createdAt).toLocaleString()}</td>
                <td>{new Date(row.updatedAt).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <Link to="/LandDetailsForm">
          <button className="btn">Add Details</button>
        </Link>

        <PDFDownloadLink document={renderDetailsTablePDF()} fileName="details_table.pdf">
          {({ blob, url, loading, error }) =>
            loading ? 'Loading document...' : 'Download PDF'
          }
        </PDFDownloadLink>
      </div>
    </div>
  );
};

// Dummy data for employee details
const employeeRows = [
  { id: 1, name: 'Athula', role: 'Manager', email: 'athula@example.com' },
  { id: 2, name: 'Bandara', role: 'Worker', email: 'bandara@example.com' },
  { id: 3, name: 'Chamidu', role: 'Supervisor', email: 'chamidu@example.com' },
  // Add more rows as needed
];

export default StatePage;
