import React from 'react';
import './listing.css';
import './listing.scss';

import { BsArrowRightCircleFill } from "react-icons/bs";
import { IoHeart } from "react-icons/io5";
import { GoHeart } from "react-icons/go";
import { IoMdAddCircleOutline } from "react-icons/io";

import cin1 from '../../../Assets/cin1.png';
import cin2 from '../../../Assets/cin2.png';
import cin3 from '../../../Assets/cin3.png';
import cin4 from '../../../Assets/cin4.png';
import user1 from '../../../Assets/user1.jpg';
import user2 from '../../../Assets/user2.jpg';
import user3 from '../../../Assets/user3.jpg';
import land from '../../../Assets/land.jpg';
import land1 from '../../../Assets/land1.png';
import land3 from '../../../Assets/land3.jpeg';

const Listing = () => {
    return (
        <div className='listingSection'>
            <div className="heading flex">
                <h1>Spices Lands</h1>
                <button className='btn flex'>
                    See All <BsArrowRightCircleFill className="icon"/>
                </button>
            </div>

            <div className="secContainer flex">
    <div className="singleItem">
        <IoHeart className="icon" />
        <img src={land} alt="Image Name" style={{ width: '100px', height: '150px' }} />
        <h3>Land 1</h3>
    </div>

    <div className="singleItem">
        <GoHeart className="icon" />
        <img src={land1} alt="Image Name" style={{ width: '100px', height: '150px' }} />
        <h3>Land 2</h3>
    </div>

    <div className="singleItem">
        <GoHeart className="icon" />
        <img src={land3} alt="Image Name" style={{ width: '100px', height: '150px' }} />
        <h3>Land 3</h3>
    </div>

    <div className="singleItem addLandItem">
      <a href="/addland" className="addLandLink">
        <IoMdAddCircleOutline className="addIcon" />
        <h3>Add Your Land</h3>
      </a>
    </div>
</div>

            <div className="sellers flex">
                <div className="topSellers">
                   <div className="heading flex">
                     <h3>Top Sellers</h3>
                     <button className='btn flex'>
                           See All <BsArrowRightCircleFill className="icon"/>
                    </button>
                   </div>

                   <div className="card flex">
                      <div className="users">
                        <img src={user1} alt="user image" />
                        <img src={user2} alt="user image" />
                        <img src={user3} alt="user image" />
                      </div>
                      <div className="cardText">
                        <span>
                            14 products sold <br/>
                            <small>
                                10 sellers <span className="date">7 Days
                                </span>
                            </small>
                        </span>
                      </div>
                   </div>
                </div>
                <div className="featuredSellers">
                   <div className="heading flex">
                     <h3>Top Sellers</h3>
                     <button className='btn flex'>
                           See All <BsArrowRightCircleFill className="icon"/>
                    </button>
                   </div>

                   <div className="card flex">
                      <div className="users">
                        <img src={user1} alt="user image" />
                        <img src={user2} alt="user image" />
                        <img src={user3} alt="user image" />
                      </div>
                      <div className="cardText">
                        <span>
                            14 products sold <br/>
                            <small>
                                10 sellers <span className="date">7 Days
                                </span>
                            </small>
                        </span>
                      </div>
                   </div>
                </div>
            </div>
        </div>
    );
}

export default Listing;
